import { cloneElement, ReactElement } from 'react';
import {
  ExportButtonProps,
  sanitizeListRestProps,
  ToolbarProps,
  TopToolbar,
  useListContext,
} from 'react-admin';

type ListActionProps = ToolbarProps &
  Pick<ExportButtonProps, 'maxResults'> & {
    filters?: ReactElement;
  };

const ListActions = ({ className, filters, ...rest }: ListActionProps) => {
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
    </TopToolbar>
  );
};

export default ListActions;
