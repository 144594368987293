import { useEffect, useState } from 'react';

import { InjectedFieldProps, PublicFieldProps, NumberField } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import { BumpResourceSheetDto } from '@vatos-pas/common';
import { DateTime } from 'luxon';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { IconButton } from '@material-ui/core';

import { PermissionsProvider } from 'providers/permissionsProvider';
import { CAN_APPROVE_BUMPOUT } from 'providers/permissions';
import { Show } from 'components/Show';
import InfoDialog from 'components/InfoDialog';
import {
  BumpoutTimesheetFields,
  BumpoutTimesheetFieldType,
} from '../views/bumpout-timesheet-list';

type WorkedHoursInputProps = {
  value: BumpoutTimesheetFieldType;
  fields: BumpoutTimesheetFields | null;
  onChangeField: (
    id: string,
    name: BumpoutTimesheetFieldType,
    value: DateTime | string | null,
  ) => void;
} & PublicFieldProps &
  InjectedFieldProps<BumpResourceSheetDto>;

const WorkedHoursInput = ({
  value,
  fields,
  onChangeField,
  record,
}: WorkedHoursInputProps) => {
  if (!record?.id) return null;

  const classes = useStyles();
  const { hasPermission } = PermissionsProvider.useContainer();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const canApprove = hasPermission(CAN_APPROVE_BUMPOUT);
  const isPendingSupervisorAction =
    record?.bumperApprovalStatus === 'PENDING_APPROVAL' ||
    record?.bumperApprovalStatus === 'PENDING_REVIEW';
  const isJobPhaseBump = record.bumpResourceType === 'JOB_PHASE_BUMP';
  const fieldValue = fields?.[record.id]?.[value];

  useEffect(() => {
    if (!record) return;

    onChangeField(record?.id as string, value, String(record?.loggedHours));
  }, [record?.loggedHours]);

  if (record.approvedHours || (isJobPhaseBump && record.loggedHours)) {
    return (
      <Box display="flex" alignItems="center" gridColumnGap={4}>
        <NumberField
          source={isJobPhaseBump ? 'loggedHours' : 'approvedHours'}
        />
        <Show condition={record.isLoggedHoursAdjusted}>
          <IconButton onClick={() => setIsModalOpen(true)}>
            <InfoOutlinedIcon className={classes.infoWarning} />
          </IconButton>
        </Show>
        <InfoDialog
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          title="Worked Hours"
          content={
            <>
              <Typography>Original: {record.loggedHours}</Typography>
              <Typography>
                Supervisor Adjusted: {record.approvedHours}
              </Typography>
            </>
          }
        />
      </Box>
    );
  }

  if (!canApprove || isJobPhaseBump || !isPendingSupervisorAction) return null;

  return (
    <Box display="flex" alignItems="center">
      <TextField
        className={classes.textField}
        type="number"
        inputProps={{
          min: 0,
          className:
            record?.bumperApprovalStatus === 'PENDING_REVIEW'
              ? classes.pendingReviewInput
              : undefined,
        }}
        value={fieldValue}
        onChange={event =>
          onChangeField(record?.id as string, value, event.target.value)
        }
      />
    </Box>
  );
};

const useStyles = makeStyles({
  textField: {
    flex: '0 0 auto',
    maxWidth: 40,
  },
  pendingReviewInput: {
    color: 'red',
    fontWeight: 'bold',
  },
  infoWarning: {
    color: '#ff9800',
  },
});

export default WorkedHoursInput;
