import React, { cloneElement, FC, Fragment, useState } from 'react';
import {
  List,
  TextField,
  DateField,
  useListContext,
  TopToolbar,
  Confirm,
  sanitizeListRestProps,
  useUnselectAll,
  useRefresh,
  useNotify,
  ExportButton,
  Pagination,
  FunctionField,
  NumberField,
  Record,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import Datagrid from 'components/Datagrid';
import { MasterSheetFilters } from '../filters/master-sheet-filters';
import { makeStyles, Box, Typography } from '@material-ui/core';
import { exporter } from 'providers/export';
import { dataProvider } from 'providers/dataProvider';
import { PermissionsProvider } from 'providers/permissionsProvider';
import { UIProvider } from 'providers/uiProvider';
import { CAN_APPROVE_PHASE } from 'providers/permissions';
import PanoramaFishEye from '@material-ui/icons/PanoramaFishEye';
import CheckIcon from '@material-ui/icons/Check';
import { AppRole } from 'providers/roles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { axiosInstance } from 'services/http';
import { CAN_CHANGE_DATES_MASTER_SHEET } from '../../../providers/permissions';
import isMobile from 'hooks/isMobile';
import { dateWithoutServerTimezone } from '../utils/dateWithoutServerTimezone';

const EARN_MARK_PHASES = [
  'Spraying',
  'Bump 1',
  'Bump 2',
  'Bump 3',
  'Bump 4',
  'Bump 5',
];

enum JobColors {
  OnTime = 'ON_TIME',
  Late = 'LATE',
  PendingReview = 'PENDING_REVIEW',
}

const JOB_COLORS = {
  [JobColors.OnTime]: '#FFFFFF',
  [JobColors.Late]: '#FFC107',
  [JobColors.PendingReview]: '#FF9900',
};

const getJobLateRowStyle = (record: Record) => {
  const {
    currentPhaseName,
    currentPhaseContractorId,
    currentPhaseApproved,
    currentPhaseIsOnTime,
    pendingReview,
  } = record;

  const isEarnMarkPhase = EARN_MARK_PHASES.includes(currentPhaseName);

  if (isEarnMarkPhase && currentPhaseContractorId && !currentPhaseApproved) {
    return { backgroundColor: JOB_COLORS[JobColors.Late] };
  }

  if (currentPhaseIsOnTime && !pendingReview) {
    return { backgroundColor: JOB_COLORS[JobColors.OnTime] };
  }

  if (pendingReview) {
    return { backgroundColor: JOB_COLORS[JobColors.PendingReview] };
  }

  return { backgroundColor: JOB_COLORS[JobColors.OnTime] };
};

const useStyles = makeStyles({
  content: {
    '@media(max-width: 1550px)': {
      overflow: 'auto',
    },
  },
  contentClosed: {
    '@media(max-width: 1550px)': {
      overflow: 'auto',
    },
  },
  root: {
    '@media(max-width: 1550px)': {
      width: 'calc(100vw - 260px)',
    },
    '@media(max-width: 850px)': {
      width: 'calc(100vw - 45px)',
      marginLeft: '15px',
    },
  },
  rootClosed: {
    '@media(max-width: 1550px)': {
      width: 'calc(100vw - 125px)',
    },
    '@media(max-width: 850px)': {
      width: 'calc(100vw - 45px)',
      marginLeft: '15px',
    },
  },
  lightgray: {
    color: '#646466',
  },
  signedStatusBadge: {
    backgroundColor: '#27AE60',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20px',
    height: '20px',
    borderRadius: '10px',
    '& > svg': {
      width: '20px',
      height: '20px',
    },
  },
});

const ListActions: FC<any> = props => {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
};

export const WithoutTimezoneDateField = props => {
  if (!props.record[props.source]) return <label></label>;
  const dateFormatted = dateWithoutServerTimezone(props.record[props.source]);

  return (
    <>
      <label>{`${
        dateFormatted.getMonth() + 1
      }/${dateFormatted.getDate()}/${dateFormatted.getFullYear()}`}</label>
    </>
  );
};

const PhaseName: FC<any> = props => (
  <FunctionField
    {...props}
    render={record => (
      <label>
        {record.pendingReview && record.currentPhaseName === 'Materials'
          ? 'Materials'
          : record.currentPhaseName}
      </label>
    )}
  />
);

const HangingContractor: FC<any> = props => {
  const classes = useStyles();

  return (
    <FunctionField
      {...props}
      render={record => {
        if (record.hangPhaseContractorName) {
          return <label>{record.hangPhaseContractorName}</label>;
        }

        if (record.hangPhaseSuggestedContractorName) {
          return (
            <label className={classes.lightgray} title="Suggested Contractor">
              {record.hangPhaseSuggestedContractorName}
            </label>
          );
        }

        return <label></label>;
      }}
    />
  );
};

const FinishingContractor: FC<any> = props => {
  const classes = useStyles();

  return (
    <FunctionField
      {...props}
      render={record => {
        if (record.finishPhaseContractorName) {
          return <label>{record.finishPhaseContractorName}</label>;
        }
        if (record.finishPhaseSuggestedContractorName) {
          return (
            <label className={classes.lightgray} title="Suggested Contractor">
              {record.finishPhaseSuggestedContractorName}
            </label>
          );
        } else return <label></label>;
      }}
    />
  );
};

const SprayingContractor: FC<any> = props => {
  const classes = useStyles();

  return (
    <FunctionField
      {...props}
      render={record => {
        if (record.sprayPhaseContractorName) {
          return <label>{record.sprayPhaseContractorName}</label>;
        }
        if (record.sprayPhaseSuggestedContractorName) {
          return (
            <label className={classes.lightgray} title="Suggested Contractor">
              {record.sprayPhaseSuggestedContractorName}
            </label>
          );
        } else return <label></label>;
      }}
    />
  );
};

const Bump1Contractor: FC<any> = props => {
  const classes = useStyles();

  return (
    <FunctionField
      {...props}
      render={record => {
        if (record.bump1PhaseContractorName) {
          return <label>{record.bump1PhaseContractorName}</label>;
        }
        if (record.bump1PhaseSuggestedContractorName) {
          return (
            <label className={classes.lightgray} title="Suggested Contractor">
              {record.bump1PhaseSuggestedContractorName}
            </label>
          );
        } else return <label></label>;
      }}
    />
  );
};

const Bump2Contractor: FC<any> = props => {
  const classes = useStyles();

  return (
    <FunctionField
      {...props}
      render={record => {
        if (record.bump2PhaseContractorName) {
          return <label>{record.bump2PhaseContractorName}</label>;
        }
        if (record.bump2PhaseSuggestedContractorName) {
          return (
            <label className={classes.lightgray} title="Suggested Contractor">
              {record.bump2PhaseSuggestedContractorName}
            </label>
          );
        } else return <label></label>;
      }}
    />
  );
};

const Bump3Contractor: FC<any> = props => {
  const classes = useStyles();

  return (
    <FunctionField
      {...props}
      render={record => {
        if (record.bump3PhaseContractorName) {
          return <label>{record.bump3PhaseContractorName}</label>;
        }
        if (record.bump3PhaseSuggestedContractorName) {
          return (
            <label className={classes.lightgray} title="Suggested Contractor">
              {record.bump3PhaseSuggestedContractorName}
            </label>
          );
        } else return <label></label>;
      }}
    />
  );
};

const Bump4Contractor: FC<any> = props => {
  const classes = useStyles();

  return (
    <FunctionField
      {...props}
      render={record => {
        if (record.bump4PhaseContractorName) {
          return <label>{record.bump4PhaseContractorName}</label>;
        }
        if (record.bump4PhaseSuggestedContractorName) {
          return (
            <label className={classes.lightgray} title="Suggested Contractor">
              {record.bump4PhaseSuggestedContractorName}
            </label>
          );
        } else return <label></label>;
      }}
    />
  );
};

const Bump5Contractor: FC<any> = props => {
  const classes = useStyles();

  return (
    <FunctionField
      {...props}
      render={record => {
        if (record.bump5PhaseContractorName) {
          return <label>{record.bump5PhaseContractorName}</label>;
        }
        if (record.bump5PhaseSuggestedContractorName) {
          return (
            <label className={classes.lightgray} title="Suggested Contractor">
              {record.bump5PhaseSuggestedContractorName}
            </label>
          );
        } else return <label></label>;
      }}
    />
  );
};

const Checked: FC<any> = props => {
  const classes = useStyles();
  return (
    <Box {...props} className={classes.signedStatusBadge}>
      <CheckIcon />
    </Box>
  );
};

const Status: FC<any> = props => {
  const jobPhaseName = props.record.currentPhaseName;
  const jobPhaseContractorId = props.record.currentPhaseContractorId;
  const jobPhaseDateRequested = props.record.currentPhaseDateRequested;
  const jobPhaseActive = props.record.activeJob;
  return (
    <>
      {jobPhaseName === 'Spraying' && jobPhaseContractorId ? (
        <Box display="flex" sx={{ gap: '3px' }} alignItems="center" {...props}>
          <Checked />
          <Typography>|</Typography>
          <Checked />
          <Checked />
          <Checked />
          <Typography>|</Typography>
          <Checked />
        </Box>
      ) : jobPhaseName === 'Spraying' ? (
        <Box display="flex" sx={{ gap: '3px' }} alignItems="center" {...props}>
          <Checked />
          <Typography>|</Typography>
          <Checked />
          <Checked />
          <Checked />
          <Typography>|</Typography>
          <PanoramaFishEye style={{ marginLeft: '-3px' }} />
        </Box>
      ) : jobPhaseName === 'Finishing' && jobPhaseContractorId ? (
        <Box display="flex" sx={{ gap: '3px' }} alignItems="center" {...props}>
          <Checked />
          <Typography>|</Typography>
          <Checked />
          <Checked />
          <PanoramaFishEye style={{ marginLeft: '-2px' }} />
        </Box>
      ) : jobPhaseName === 'Finishing' && jobPhaseDateRequested ? (
        <Box display="flex" sx={{ gap: '3px' }} alignItems="center" {...props}>
          <Checked />
          <Typography>|</Typography>
          <Checked />
          <PanoramaFishEye style={{ marginLeft: '-2px' }} />
        </Box>
      ) : jobPhaseName === 'Finishing' ? (
        <Box display="flex" sx={{ gap: '3px' }} alignItems="center" {...props}>
          <Checked />
          <Typography>|</Typography>
          <PanoramaFishEye style={{ marginLeft: '-3px' }} />
        </Box>
      ) : jobPhaseActive || (!jobPhaseActive && jobPhaseName === 'Hanging') ? (
        <Box display="flex" alignItems="center" {...props}>
          <PanoramaFishEye style={{ marginLeft: '-2px' }} />
        </Box>
      ) : null}
    </>
  );
};

const PostBulkActionButtons = props => {
  const [open, setOpen] = useState(false);
  const [openRequestConfirm, setOpenRequestConfirm] = useState(false);
  const { hasPermission } = PermissionsProvider.useContainer();

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const unselectAll = useUnselectAll();

  const handleConfirm = async () => {
    try {
      await dataProvider.create('job-phase-work-approval-batch/bulk', {
        data: {
          bulk: props.selectedIds.map(item => ({
            id: item,
          })),
        },
      });
      props.notify('Approved with success!');
      props.refresh();
      unselectAll('job-master-sheet');
      unselectAll('job-warranty-sheet');
    } catch (error) {
      const errors = error.response.data.message;
      errors.forEach(item => {
        props.notify(
          'Lot:' + item.job.lot + ' ' + item.errorMessage,
          'warning',
        );
      });
    } finally {
      setOpen(false);
    }
  };

  const handleConfirmRequestResource = async () => {
    try {
      await dataProvider.create('job-phase-request-resource-batch/bulk', {
        data: {
          bulk: props.selectedIds.map(item => ({
            id: item,
          })),
        },
      });
      props.notify('Resource request has been submitted.');
      props.refresh();
      unselectAll('job-master-sheet');
      unselectAll('job-warranty-sheet');
    } catch (error) {
      const errors = error.response.data.message;
      errors.forEach(item => {
        props.notify(
          'Lot:' + item.job.lot + ' ' + item.errorMessage,
          'warning',
        );
      });
    } finally {
      setOpenRequestConfirm(false);
    }
  };

  return (
    <Fragment>
      {hasPermission(CAN_APPROVE_PHASE) && (
        <Button onClick={handleClick}>
          <label>Approve</label>
        </Button>
      )}
      {hasPermission(CAN_APPROVE_PHASE) && (
        <Button onClick={() => setOpenRequestConfirm(true)}>
          <label>Request Resource</label>
        </Button>
      )}
      <Confirm
        isOpen={openRequestConfirm}
        title="Request Resources"
        content="Are you sure you want to request resource for these phases? Attention: If any of the selected jobs are NOT requested correctly, NO action will be taken on any job."
        onConfirm={handleConfirmRequestResource}
        onClose={() => setOpenRequestConfirm(false)}
      />
      <Confirm
        isOpen={open}
        title="Approve phases"
        content="Are you sure you want to approve these phases? Attention: If any of the selected jobs are NOT able to be approved, NO action will be taken on any job."
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

const getMenuColumn = (MenuItem, source, label, warranty, customProps = {}) => {
  const isWarranty = window.location.href.indexOf('job-warranty-sheet') !== -1;
  if (isWarranty && warranty) {
    return <MenuItem source={source} label={label} {...customProps} />;
  }
  if (!isWarranty && !warranty) {
    return <MenuItem source={source} label={label} {...customProps} />;
  }
  return null;
};

const FinishDateField = ({ record, onChange, fields }) => {
  const id = record?.id ? String(record?.id) : '';
  let hangingDate = record?.finishPhaseDateStart
    ? dateWithoutServerTimezone(record?.finishPhaseDateStart)
    : null;

  if (fields[id] && fields[id].customFinishDateStart !== undefined) {
    hangingDate = fields[id].customFinishDateStart;
  }

  const onDateChange = (value: MaterialUiPickersDate) => {
    onChange('customFinishDateStart')(id)({ target: { value } });
    onChange('submitted')(id)({ target: { value: false } });
  };

  const { hasPermission } = PermissionsProvider.useContainer();

  const isSupervisor = hasPermission(CAN_CHANGE_DATES_MASTER_SHEET);

  return (
    <Box
      zIndex={0}
      display="flex"
      minWidth={140}
      onClick={e => e.stopPropagation()}
    >
      <KeyboardDatePicker
        disableToolbar
        inputProps={{ placeholder: '' }}
        placeholder={''}
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-inline"
        // maxDate={new Date()}
        fullWidth
        KeyboardButtonProps={{ 'aria-label': 'Change Date' }}
        onChange={onDateChange}
        defaultValue={hangingDate}
        value={hangingDate}
        disabled={!isSupervisor}
      />
    </Box>
  );
};
const SprayerDateField = ({ onChange, record, fields }) => {
  const id = record?.id ? String(record?.id) : '';
  const { hasPermission } = PermissionsProvider.useContainer();

  const isSupervisor = hasPermission(CAN_CHANGE_DATES_MASTER_SHEET);

  let sprayerDate = record.sprayPhaseDateStart
    ? dateWithoutServerTimezone(record.sprayPhaseDateStart)
    : null;
  if (fields[id] && fields[id].customSprayDateStart !== undefined) {
    sprayerDate = fields[id].customSprayDateStart;
  }

  const onDateChange = (value: MaterialUiPickersDate) => {
    onChange('customSprayDateStart')(id)({ target: { value } });
    onChange('submitted')(id)({ target: { value: false } });
  };

  return (
    <Box display="flex" minWidth={140} onClick={e => e.stopPropagation()}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        margin="normal"
        id="date-picker-inline"
        // maxDate={new Date()}
        fullWidth
        KeyboardButtonProps={{ 'aria-label': 'Change Date' }}
        onChange={onDateChange}
        defaultValue={sprayerDate}
        value={sprayerDate}
        disabled={!isSupervisor}
      />
    </Box>
  );
};

const SubmitButton = ({
  loading,
  onSubmit,
  record = { id: '' },
  fields,
  ...props
}: any) => {
  const { hasPermission } = PermissionsProvider.useContainer();

  const isSupervisor = hasPermission(CAN_CHANGE_DATES_MASTER_SHEET);

  let disabled = true;

  const changed = fields[record.id];

  if (
    (changed &&
      changed.customSprayDateStart === null &&
      record.sprayPhaseDateStart) ||
    (changed &&
      changed.customSprayDateStart &&
      changed.customSprayDateStart.toLocaleString() !==
        new Date(record.sprayPhaseDateStart).toLocaleDateString())
  ) {
    disabled = false;
  }

  if (
    (changed &&
      changed.customFinishDateStart === null &&
      record.finishPhaseDateStart) ||
    (changed &&
      changed.customFinishDateStart &&
      changed.customFinishDateStart.toLocaleString() !==
        new Date(record.finishPhaseDateStart).toLocaleDateString())
  ) {
    disabled = false;
  }

  if (loading || (changed && changed.submitted)) {
    disabled = true;
  }

  if (!isSupervisor) {
    return null;
  }

  return (
    <Box onClick={e => e.stopPropagation()}>
      <Button
        variant="contained"
        color="primary"
        {...props}
        onClick={() => onSubmit(record)}
        disabled={disabled}
      >
        Submit
      </Button>
    </Box>
  );
};

const menuFields = [
  {
    MenuComponent: HangingContractor,
    source: 'hangPhaseContractorName',
    label: 'Hanger',
    warranty: false,
  },
  {
    MenuComponent: WithoutTimezoneDateField,
    source: 'hangPhaseDateStart',
    label: 'Hang Date',
    warranty: false,
  },
  {
    MenuComponent: FinishingContractor,
    source: 'finishPhaseContractorName',
    label: 'Finisher',
    warranty: false,
  },
  {
    MenuComponent: FinishDateField,
    source: 'finishPhaseDateStart',
    label: 'Finish Date',
    warranty: false,
  },
  {
    MenuComponent: SprayingContractor,
    source: 'sprayPhaseContractorName',
    label: 'Sprayer',
    warranty: false,
  },
  {
    MenuComponent: SprayerDateField,
    source: 'sprayPhaseDateStart',
    label: 'Spray Date',
    warranty: false,
  },
  {
    MenuComponent: Bump1Contractor,
    source: 'bump1PhaseContractorName',
    label: 'Bump1',
    warranty: true,
  },
  {
    MenuComponent: WithoutTimezoneDateField,
    source: 'bump1PhaseDateStart',
    label: 'Bump1 Date',
    warranty: true,
  },
  {
    MenuComponent: Bump2Contractor,
    source: 'bump2PhaseContractorName',
    label: 'Bump2',
    warranty: true,
  },
  {
    MenuComponent: DateField,
    source: 'bump2PhaseDateStart',
    label: 'Bump2 Date',
    warranty: true,
  },
  {
    MenuComponent: Bump3Contractor,
    source: 'bump3PhaseContractorName',
    label: 'Bump3',
    warranty: true,
  },
  {
    MenuComponent: DateField,
    source: 'bump3PhaseDateStart',
    label: 'Bump3 Date',
    warranty: true,
  },
  {
    MenuComponent: Bump4Contractor,
    source: 'bump4PhaseContractorName',
    label: 'Bump4',
    warranty: true,
  },
  {
    MenuComponent: DateField,
    source: 'bump4PhaseDateStart',
    label: 'Bump4 Date',
    warranty: true,
  },
  {
    MenuComponent: Bump5Contractor,
    source: 'bump5PhaseContractorName',
    label: 'Bump5',
    warranty: true,
  },
  {
    MenuComponent: DateField,
    source: 'bump5PhaseDateStart',
    label: 'Bump5 Date',
    warranty: true,
  },
];

interface Field {
  customFinishDateStart: MaterialUiPickersDate;
  customSprayDateStart: MaterialUiPickersDate;
  submitted?: boolean;
}

interface Fields {
  [id: string]: Field;
}

const PreRockField = props => {
  const preRockText = props.record.preRock === true ? 'Yes' : 'No';
  return <label>{preRockText}</label>;
};

export const MasterSheetList: FC<any> = props => {
  const { hasRole } = PermissionsProvider.useContainer();
  const classes = useStyles(props);
  const notify = useNotify();
  const refresh = useRefresh();
  const [fields, setFields] = useState<Fields>({});
  const { opened } = UIProvider.useContainer();
  const [loading, setLoading] = useState(false);

  const isSupervisor = hasRole(AppRole.Supervisor);

  const isWarranty = window.location.href.indexOf('job-warranty-sheet') !== -1;

  const filterDefault = { 'activeJob||$eq': true };

  const onFieldChange = (field: string) => (id: string) => (event: any) =>
    setFields(oldFields => ({
      ...oldFields,
      [id]: { ...oldFields[id], [field]: event.target.value },
    }));

  const submitDates = async record => {
    const { id, jobId, sprayPhaseDateStart } = record;
    const stringfiedId = String(id);

    const row = fields[stringfiedId];
    if (!row) return;

    setLoading(true);
    try {
      const data: any = {};

      if (Object.keys(row).indexOf('customFinishDateStart') !== -1) {
        const customFinishDateStart =
          row?.customFinishDateStart?.toISO() || null;
        data.customFinishDateStart = customFinishDateStart;
      }

      if (Object.keys(row).indexOf('customSprayDateStart') !== -1) {
        const customSprayDateStart = row?.customSprayDateStart?.toISO() || null;
        data.customSprayDateStart = customSprayDateStart;
      }

      await axiosInstance.patch(`/job/${jobId}/custom-phase-date`, data);

      notify('Changes were made successfully!');
      refresh();
    } catch (error) {
      const newFields = { ...fields };
      newFields[stringfiedId].customSprayDateStart = sprayPhaseDateStart;
      setFields(newFields);

      notify(`${error?.response?.data?.message || error.message}`, 'warning');
    } finally {
      const newFields = { ...fields };
      newFields[stringfiedId].submitted = true;
      setFields(newFields);
      setLoading(false);
    }
  };

  return (
    <List
      className="list"
      {...props}
      exporter={exporter('master-sheet')}
      filters={<MasterSheetFilters isWarranty={isWarranty} />}
      sort={{
        field: isWarranty ? 'bump1PhaseDateStart' : 'hangPhaseDateStart',
        order: 'ASC',
      }}
      bulkActionButtons={
        !isWarranty ? (
          <PostBulkActionButtons refresh={refresh} notify={notify} />
        ) : undefined
      }
      actions={<ListActions maxResults={20000} />}
      classes={{
        content: opened ? classes.content : classes.contentClosed,
        root: opened ? classes.root : classes.rootClosed,
      }}
      filterDefaultValues={isWarranty ? {} : filterDefault}
      pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50]} />}
      perPage={50}
    >
      <Datagrid
        // style={{ marginLeft: '16px' }}
        rowClick="edit"
        isHeaderSelectable={!isWarranty}
        isRowSelectable={() => !isWarranty}
        rowStyle={getJobLateRowStyle}
      >
        {!isSupervisor && (
          <TextField source="supervisorFullName" label="Supervisor" />
        )}
        <TextField source="jobLot" label="Lot" />
        <TextField source="jobBlock" label="Block" />
        <TextField source="builderName" label="Builder" />
        <TextField source="subdivisionName" label="Subdivision" />
        <TextField source="payableBoardsOrderedTotal" label="Payable Boards" />
        <TextField
          source="payableBoardsReceiptTotal"
          label="Supervisor Count"
        />
        {!isMobile() && (
          <TextField source="payableOverride" label="Payable Override" />
        )}
        <PhaseName source="currentPhaseName" label="Current Phase" />
        {!isWarranty && <Status isWarranty label="Status" />}
        {!isWarranty && (
          <NumberField
            label="Current Phase Days"
            source="currentPhaseDaysCount"
          />
        )}

        {!isWarranty && <NumberField label="Job Days" source="jobDaysCount" />}
        {menuFields.map(({ MenuComponent, source, label, warranty }) =>
          getMenuColumn(MenuComponent, source, label, warranty, {
            onChange: onFieldChange,
            fields,
          }),
        )}
        <PreRockField source="preRock" label="Pre-Rock" />
        {!isWarranty && (
          <SubmitButton
            loading={loading}
            fields={fields}
            onSubmit={submitDates}
          />
        )}
      </Datagrid>
    </List>
  );
};
